import { Card } from "./sub-components";
import * as anchor from "@project-serum/anchor2";
import { OCR2Feed } from "@chainlink/solana-sdk";
import { useEffect, useState } from "react";
import { Wallet } from "@project-serum/anchor"

export default function PriceFeedCard(): React.ReactElement {
  const [btcPrice, setBtcPrice] = useState(0);
  const [ethPrice, setEthPrice] = useState(0);
  const [solPrice, setSolPrice] = useState(0);
  const [linkPrice, setLinkPrice] = useState(0);

  const DIVISOR = 100000000;

  useEffect(() =>{
    getData()
  })

  async function getData() {
    // const provider = anchor.AnchorProvider.env();
    // anchor.setProvider(provider);

    const connection = new anchor.web3.Connection(
      "https://go.getblock.io/567a9ef9fe9d4c64ae90de8270c381ec",
      { commitment: "max" }
    );

    const wallet = new Wallet(anchor.web3.Keypair.generate());

    const provider = new anchor.AnchorProvider(connection, wallet, {
      commitment: "max",
      preflightCommitment: "max",
      skipPreflight: false,
    });

    const CHAINLINK_FEED_ADDRESS_BTC_USD =
      "CGmWwBNsTRDENT5gmVZzRu38GnNnMm1K5C3sFiUUyYQX"; //BTC-USD Mainnet Feed

      const CHAINLINK_FEED_ADDRESS_ETH_USD =
      "5WyTBrEgvkAXjTdYTLY9PVrztjmz4edP5W9wks9KPFg5"; //ETH-USD Mainnet Feed
      const CHAINLINK_FEED_ADDRESS_SOL_USD =
      "CcPVS9bqyXbD9cLnTbhhHazLsrua8QMFUHTutPtjyDzq"; //SOL-USD Mainnet Feed
      const CHAINLINK_FEED_ADDRESS_LINK_USD =
      "EH32v4UHcwH6S7gLTRvEBEyCTJrVbhRiJE7QEGoqd4NU"; //LINK-USD Mainnet Feed

    const CHAINLINK_PROGRAM_ID = new anchor.web3.PublicKey(
      "cjg3oHmg9uuPsP8D6g29NWvhySJkdYdAo9D25PRbKXJ"
    );
    const feedAddress = new anchor.web3.PublicKey(CHAINLINK_FEED_ADDRESS_BTC_USD);
    const feedAddressETH = new anchor.web3.PublicKey(CHAINLINK_FEED_ADDRESS_ETH_USD);
    const feedAddressSOL = new anchor.web3.PublicKey(CHAINLINK_FEED_ADDRESS_SOL_USD);
    const feedAddressLINK = new anchor.web3.PublicKey(CHAINLINK_FEED_ADDRESS_LINK_USD);

    //load the data feed account
    let dataFeed = await OCR2Feed.load(CHAINLINK_PROGRAM_ID, provider);

    //listen for events agains the price feed, and grab the latest rounds price data
    dataFeed.onRound(feedAddress, (event) => {
      // console.log((event.answer.toNumber() / DIVISOR).toFixed(2));
      setBtcPrice((event.answer.toNumber() / DIVISOR));
    });

    dataFeed.onRound(feedAddressETH, (event) => {
      // console.log('eth',(event.answer.toNumber() / DIVISOR));
      setEthPrice((event.answer.toNumber() / DIVISOR));
    });

    dataFeed.onRound(feedAddressSOL, (event) => {
      // console.log('sol',(event.answer.toNumber() / DIVISOR));
      setSolPrice((event.answer.toNumber() / DIVISOR));
    });

    dataFeed.onRound(feedAddressLINK, (event) => {
      // console.log('link',(event.answer.toNumber() / DIVISOR));
      setLinkPrice((event.answer.toNumber() / DIVISOR));
    });

    //block execution and keep waiting for events to be emitted with price data
    await new Promise(function () {});
  }

  return (
    <>
      <section className="card-list">
        <Card title="Chainlink Price Feed" quote="BTC/USD" price={btcPrice} />

        <Card title="Chainlink Price Feed" quote="ETH/USD" price={ethPrice} />

        <Card title="Chainlink Price Feed" quote="SOL/USD" price={solPrice} />

        <Card title="Chainlink Price Feed" quote="LINK/USD" price={linkPrice} />
      </section>
    </>
  );
}
