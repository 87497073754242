import './styles.css'

export default function Card({title, quote, price}): JSX.Element {
  function numberWithCommas(x: number) {
    return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <article className="card">
      <header className="card-header">
        <p>{title}</p>
        <h2>{quote}</h2>
      </header>
      {price === 0 ? <h1>Fetching...</h1> : <h1>${numberWithCommas(price)}</h1>}
    </article>
  );
};
