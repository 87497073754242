import * as anchor from '@project-serum/anchor'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useEffect, useState } from 'react'
import { initGemFarm } from '../components/nft-staking/gem-farm'
import NftStaking from '../components/nft-staking/nftStaking'

// TODO: CONNECTION RPC NFT FARMING
const NftFarm = () => {
  const [farmPool, setFarmPool] = useState([])
  const connection = new anchor.web3.Connection(
    'https://go.getblock.io/567a9ef9fe9d4c64ae90de8270c381ec',
    { commitment: 'max' }
  )
  const wallet = useWallet()
  const { publicKey } = wallet
  const getFarmPool = async () => {
    console.log('nftFarm')
    let gf = await initGemFarm(connection, wallet.adapter)
    let foundFarms = await gf.fetchAllFarmPDAs(
      new PublicKey('AzurEAAY66JwU6Rxntp4hmSryetBFuS8hvBHRSR4Tdcp')
    )
    setFarmPool(foundFarms)
  }
  useEffect(() => {
    getFarmPool()
  }, [])
  return (
    <div>
      {farmPool.map((item, key) => (
        <NftStaking farm={item} index={key} key={key}></NftStaking>
      ))}
    </div>
  )
}

export default NftFarm
